import { data } from '~/data/data.js'

riot.tag2('data-item', '<span>{title}</span> <span class="gteviz-dataitem__close" if="{active}"></span>', '', 'class="gteviz-dataitem {gteviz-dataitem--active : active}" onclick="{toggle}"', function(opts) {

  this.title = this.item[`title_${this.LANG}`]
  this.active = false

  this.toggle = () => {
    if (!this.parent.blocked) {
      this.active ?
        this.CONTROL.trigger(this.EVENTS.removeItem, this.item) :
        this.CONTROL.trigger(this.EVENTS.addItem, this.item)
      this.update({ active: !this.active })
    }
  }

  this.CONTROL.on(this.EVENTS.reset, () => this.update({ active: false }))

  this.CONTROL.on(this.EVENTS.addItem, (item, initial=false) => {
    initial && item === this.item && this.update({ active: true })
  })

  this.CONTROL.on(this.EVENTS.unselectItem, item => {
    item === this.item && this.update({ active: false })
  })
});

riot.tag2('data-selector', '<data-item each="{item in items}">', '', 'class="gteviz-dataselector"', function(opts) {

  this.items = data[this.SCOPE]
  this.blocked = false

  this.on('mount', () => {
    const lowItems = this.items.filter(({value}) => value < this.STORE.maxValue[this.SCOPE] / 2)
    const initialItem = lowItems[Math.floor(Math.random() * lowItems.length)]
    this.CONTROL.trigger(this.EVENTS.addItem, initialItem, true)
  })

  this.CONTROL.on(this.EVENTS.reachedTarget, () => {
    this.update({ blocked: true })
  })

  this.CONTROL.on(this.EVENTS.reset, () => {
    this.update({ blocked: false })
  })

  this.CONTROL.on(this.EVENTS.unselectItem, () => {
    this.update({ blocked: false })
  })
});
